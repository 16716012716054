import { useEffect, useState } from "react";

function useIsMobile(breakpoint = 1024) {
  const [isMobile, setIsMobile] = useState(
    window.matchMedia(`(max-width: ${breakpoint}px)`).matches
  );
  const [currentWindowWidth, setCurrentWindowWidth] = useState(
    window.innerWidth
  );

  useEffect(() => {
    const handleResize = () => {
      const newIsMobile = window.matchMedia(
        `(max-width: ${breakpoint}px)`
      ).matches;
      const newWidth = window.innerWidth;

      if (newIsMobile !== isMobile) setIsMobile(newIsMobile);
      if (newWidth !== currentWindowWidth) setCurrentWindowWidth(newWidth);
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [breakpoint, isMobile, currentWindowWidth]);

  return { isMobile, currentWindowWidth };
}

export default useIsMobile;
