import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGem } from "@fortawesome/pro-light-svg-icons";
import {
  faXmark,
  faBlind,
  faEye,
  faBan,
} from "@fortawesome/sharp-solid-svg-icons";
import {
  faThumbTack,
  faMicrophone,
  faPencil,
} from "@fortawesome/free-solid-svg-icons";
import { UseCommentContext } from "../../../Contexts/CommentContext";

function CommentIcon(props) {
  const { RefreshComment, EditComment, ProcessBanRequest, basicInfo } =
    UseCommentContext();

  async function ProcessClick() {
    if (props.fieldName.includes("Banned")) {
      await ProcessBanRequest({
        userId: props.comment.userId,
        banType: props.fieldName.replace("Banned", ""),
      });
    } else {
      await EditComment({
        id: props.comment.id,
        fields: [
          {
            name: props.fieldName,
            value: !props.currentValue,
          },
          {
            name: "CommentAdmin",
            value: basicInfo.canSeeAdminButtons,
          },
        ],
      });
    }
    RefreshComment({ id: props.comment.id });
  }

  function GetIcon() {
    switch (props.fieldName) {
      case "delete":
        return faXmark;
      case "shadowBanned":
        return faBlind;
      case "fullBanned":
        return faEye;
      case "hidden":
        return faBan;
      case "pinned":
        return faThumbTack;
      case "topComment":
        return faGem;
      case "joker":
        return faMicrophone;
      case "masterDebater":
        return faPencil;
      default:
        return faXmark;
    }
  }

  return props.visible ? (
    <FontAwesomeIcon
      icon={GetIcon()}
      className={
        "commentIcon " +
        props.className +
        (basicInfo.canSeeAdminButtons ? " pointer" : "")
      }
      data-tooltip-id="commentTooltip"
      data-tooltip-content={props.toolTip}
      onClick={ProcessClick}
    />
  ) : null;
}

export default CommentIcon;
