import React, { useEffect, useState } from "react";
import * as Constants from "../../Constants/APIConstants";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faPenToSquare } from "@fortawesome/free-solid-svg-icons";
import { faRectanglePro } from "@fortawesome/pro-solid-svg-icons";
import CommentIcon from "./CommentAdminActions/CommentIcon";
import CommentReply from "./CommentUserActions/CommentReply";
import UpDownVote from "./CommentUserActions/UpDownVote";
import * as Utilities from "../../Constants/Utilities";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { UseCommentContext } from "../../Contexts/CommentContext";
import { Icon } from "@iconify/react/dist/iconify.js";
import { UseNavigationContext } from "../../Contexts/NavigationContext";

function Comment(props) {
  const [minimizeChildren, SetMinimizeChildren] = useState(
    props.parentLevel > 2
  );
  const [children, SetChildren] = useState([]);
  const [dimParent, SetDimParent] = useState(false);
  const [editMode, SetEditMode] = useState(false);
  const [editText, SetEditText] = useState(props.comment.content);

  const { HandleLinkClick } = UseNavigationContext();

  const {
    basicInfo,
    FullRefresh,
    LoginCheck,
    sortedComments,
    allOneLevel,
    EditComment,
    RefreshComment,
  } = UseCommentContext();

  const userHref = "user/" + props.comment.postingUser?.username;
  const videoHref = `video/${props.comment.videoSlug}`;

  function ToggleMinimizeChildren() {
    SetMinimizeChildren(!minimizeChildren);
  }

  function EditMode() {
    SetEditMode(!editMode);
  }

  function ChangeEditText(event) {
    SetEditText(event.target.value);
  }

  async function SubmitEdit() {
    let currentTime = new Date();
    currentTime.setHours(
      currentTime.getHours() + currentTime.getTimezoneOffset() / 60
    );
    let response = await EditComment({
      id: props.comment.id,
      fields: [
        {
          name: "_content",
          value: editText,
        },
        {
          name: "edited",
          value: currentTime.toISOString(),
        },
      ],
    });
    if (response.status === 200) {
      RefreshComment({ id: props.comment.id });
      SetEditMode(false);
    }
  }

  function GetAvatarUrl() {
    if (
      props.comment?.postingUser?.avatar != null &&
      props.comment.postingUser.avatar.trim() !== ""
    ) {
      return Constants.IMAGES_BASE_URL + props.comment.postingUser.avatar;
    }
    return Constants.IMAGES_BASE_URL + Constants.DEFAULT_AVATAR_IMAGE;
  }

  useEffect(() => {
    if (!allOneLevel) {
      SetChildren(
        sortedComments.filter((c) => c.parentId === props.comment.id)
      );
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortedComments, props.comment.id]);

  return (
    <div className="commentContainer">
      {/* Please see the api for the highlighting rules. */}
      <div
        className={
          props.comment.userId === basicInfo.userId
            ? "commentContentContainer highlight"
            : props.comment.postingUser?.check?.length > 4 &&
                props.comment.postingUser?.check[4] === "1"
              ? "commentContentContainer highlight1"
              : props.comment.postingUser?.check?.length > 4 &&
                  props.comment.postingUser?.check[4] === "2"
                ? "commentContentContainer highlight3"
                : props.comment.check?.length > 4 &&
                    props.comment.check[4] === "1"
                  ? "commentContentContainer highlight4"
                  : props.comment.ml === 1
                    ? "commentContentContainer highlight2"
                    : "commentContentContainer"
        }
      >
        <a
          href={userHref}
          className={
            props.comment.postingUser?.isPremium
              ? "commentAvatar commentProUser"
              : "commentAvatar"
          }
          onClick={(e) => {
            HandleLinkClick(e, userHref);
          }}
        >
          <LazyLoadImage src={GetAvatarUrl()} alt="Avatar" />
        </a>
        <div className="commentBody">
          <div className="commentHeader">
            <a
              href={userHref}
              className="commentAuthor"
              onClick={(e) => {
                HandleLinkClick(e, userHref);
              }}
            >
              {props.comment.postingUser?.username}
            </a>
            {props.comment.postingUser?.isPremium ? (
              <div
                className="commentProText"
                data-tooltip-id="commentTooltip"
                data-tooltip-content="Premium User"
              >
                <a
                  href="premium"
                  onClick={(e) => {
                    HandleLinkClick(e, "premium");
                  }}
                >
                  <FontAwesomeIcon
                    id={props.comment.id + "proIcon"}
                    className="commentProIcon"
                    icon={faRectanglePro}
                  />
                </a>
              </div>
            ) : null}
            <div className="commentMetadata">
              <div className="commentDate">
                {Utilities.GetPrettyDate(props.comment.createdDate) +
                  (allOneLevel
                    ? ' on "' + props.comment.videoTitle + '"'
                    : "") +
                  (props.comment.edited != null
                    ? " (edited " +
                      Utilities.GetPrettyDate(props.comment.edited) +
                      ")"
                    : "")}
              </div>
              <CommentIcon
                className={
                  "commentAwardPinned" +
                  (!props.comment.pinned ? " commentAwardFaded" : "")
                }
                comment={props.comment}
                fieldName="pinned"
                currentValue={props.comment.pinned}
                toolTip={props.comment.pinned ? "Unpin Comment" : "Pin Comment"}
                visible={basicInfo.canSeeAdminButtons || props.comment.pinned}
                canClick={basicInfo.canSeeAdminButtons}
              />
              <CommentIcon
                className={
                  "commentAwardTopComment" +
                  (!props.comment.topComment ? " commentAwardFaded" : "")
                }
                comment={props.comment}
                fieldName="topComment"
                currentValue={props.comment.topComment}
                toolTip={
                  props.comment.topComment
                    ? "Remove Top Comment"
                    : "Top Comment"
                }
                visible={
                  basicInfo.canSeeAdminButtons || props.comment.topComment
                }
                canClick={basicInfo.canSeeAdminButtons}
              />
              <CommentIcon
                className={
                  "commentAwardJoker" +
                  (!props.comment.joker ? " commentAwardFaded" : "")
                }
                comment={props.comment}
                fieldName="joker"
                currentValue={props.comment.joker}
                toolTip={props.comment.joker ? "Remove Joker" : "Joker"}
                visible={basicInfo.canSeeAdminButtons || props.comment.joker}
                canClick={basicInfo.canSeeAdminButtons}
              />
              <CommentIcon
                className={
                  "commentAwardMasterDebater" +
                  (!props.comment.masterDebater ? " commentAwardFaded" : "")
                }
                comment={props.comment}
                fieldName="masterDebater"
                currentValue={props.comment.masterDebater}
                toolTip={
                  props.comment.masterDebater
                    ? "Remove Master Debater"
                    : "Master Debater"
                }
                visible={
                  basicInfo.canSeeAdminButtons || props.comment.masterDebater
                }
                canClick={basicInfo.canSeeAdminButtons}
              />
            </div>
          </div>
          {!editMode ? (
            <div className="commentText">
              {props.comment.content != null &&
              props.comment.content.trim() !== ""
                ? props.comment.content
                : "[deleted by " + props.comment.postingUser?.username + "]"}
            </div>
          ) : (
            <div className="commentEditForm">
              <textarea
                className="commentEditFormInput"
                type="textarea"
                value={editText}
                onChange={ChangeEditText}
              />
              <div class="commentEditPostButton">
                <FontAwesomeIcon
                  className="commentEditPostButtonIcon"
                  icon={faPenToSquare}
                />
                <div className="commentEditPostButtonText" onClick={SubmitEdit}>
                  Edit Comment
                </div>
              </div>
            </div>
          )}
          <div className="commentActions">
            <UpDownVote
              comment={props.comment}
              vote={
                props.comment.votes.length > 0 ? props.comment.votes[0] : null
              }
            />
            <CommentReply
              comment={props.comment}
              basicInfo={basicInfo}
              FullRefresh={FullRefresh}
              LoginCheck={LoginCheck}
            />
            {basicInfo.userId != null &&
            basicInfo.userId.toString() === props.comment.userId.toString() ? (
              <>
                <FontAwesomeIcon
                  icon={faEdit}
                  className="commentEdit"
                  id={props.comment.id + "commentEdit"}
                  data-tooltip-id="commentTooltip"
                  data-tooltip-content="Edit"
                  onClick={EditMode}
                />
              </>
            ) : (
              <div className="commentEdit"></div>
            )}
            <div className="commentAdminActions">
              <CommentIcon
                comment={props.comment}
                fieldName="deleted"
                currentValue={props.comment.deleted}
                FullRefresh={FullRefresh}
                toolTip="Delete Comment"
                visible={basicInfo.canSeeAdminButtons}
              />
              <CommentIcon
                comment={props.comment}
                fieldName="shadowBanned"
                isBan={true}
                FullRefresh={FullRefresh}
                toolTip="Shadowban Commenter"
                visible={basicInfo.canSeeAdminButtons}
              />
              <CommentIcon
                comment={props.comment}
                fieldName="fullBanned"
                isBan={true}
                FullRefresh={FullRefresh}
                toolTip="Ban Commenter"
                visible={basicInfo.canSeeAdminButtons}
              />
              <CommentIcon
                comment={props.comment}
                fieldName="hidden"
                currentValue={props.comment.hidden}
                FullRefresh={FullRefresh}
                toolTip={
                  props.comment.hidden ? "Unhide Comment" : "Hide Comment"
                }
                visible={basicInfo.canSeeAdminButtons}
              />
            </div>
          </div>
        </div>
      </div>
      {children.length > 0 ? (
        <div
          className={"commentChildren" + (minimizeChildren ? " minimized" : "")}
        >
          <div
            className={
              "commentMinimizeChildren" + (dimParent ? " parentDimmed" : "")
            }
            onMouseEnter={() => SetDimParent(true)}
            onMouseLeave={() => SetDimParent(false)}
            onClick={ToggleMinimizeChildren}
          >
            {minimizeChildren ? (
              <div className="commentMinimizeChildrenExpand">
                <Icon icon="mdi:plus" />
                <div className="commentMinimizeChildrenExpandText">
                  Show Replies
                </div>
              </div>
            ) : (
              <div className="commentMinimizeChildrenExpand">
                <div className="commentMinimizeChildrenExpandText">
                  <Icon icon="mdi:minimize" />
                </div>
              </div>
            )}
          </div>
          <div
            onClick={minimizeChildren ? ToggleMinimizeChildren : null}
            className="commentChildList"
          >
            {minimizeChildren
              ? null
              : children.map((child, index) => (
                  <Comment
                    key={index}
                    comment={child}
                    basicInfo={basicInfo}
                    FullRefresh={FullRefresh}
                    LoginCheck={LoginCheck}
                    parentLevel={props.parentLevel + 1}
                  />
                ))}
          </div>
        </div>
      ) : null}
      {basicInfo.username != null && (
        // eslint-disable-next-line jsx-a11y/anchor-has-content
        <a
          href={videoHref}
          className="commentOverlay"
          onClick={(e) => {
            e.stopPropagation();
            HandleLinkClick(e, videoHref);
          }}
        ></a>
      )}
    </div>
  );
}

export default Comment;
