import React, { useEffect, useState } from "react";
import { UseVideoContext } from "../Contexts/VideoContext";
import VideoListMultiRow from "./VideoListMultiRow";
import VideoListSingleRow from "./VideoListSingleRow";

function VideoList(props) {
  const [videos, SetVideos] = useState([]);

  const { GetVideos, ChangeRecommendedVideos } = UseVideoContext();

  async function RetrieveVideos() {
    if (props.videos != null) {
      SetVideos(props.videos);
      return;
    }
    let params = {
      amount: props.amount ?? 20,
      random: props.random ?? false,
      search: props.search ?? null,
      categories: props.categories ?? null,
      channel: props.channel ?? null,
      sortBy: props.sortBy ?? "date",
      startDate: props.startDate ?? null,
      endDate: props.endDate ?? null,
      page: props.loadsCompleted ?? null,
      ids: props.videoIds ?? null,
      includeTotalPages: props.includeTotalPages ?? false,
    };

    let genericVideos = await GetVideos(params);
    if (genericVideos.error) {
      return;
    }
    let listComplete = genericVideos.data?.length < props.amount;
    if (
      listComplete !== props.listComplete &&
      props.ChangeListCompleted != null
    ) {
      props.ChangeListCompleted(listComplete);
    }
    if (
      props.includeTotalPages &&
      genericVideos.data != null &&
      genericVideos.data[0] != null
    ) {
      let totalPagesString = genericVideos.data[0];
      let totalPages = parseInt(totalPagesString.split(":")[1]);
      if (props.ChangeTotalPages != null) {
        props.ChangeTotalPages(totalPages);
      }
    }
    if (props.loadsCompleted > 1 && videos != null && !props.isPaginated) {
      SetVideos(videos.concat(genericVideos.data));
      return;
    }
    SetVideos(genericVideos.data);
  }

  useEffect(() => {
    if (props.loadsCompleted > 0) {
      if (props.loadsCompleted <= 1 && props.videos == null) {
        SetVideos([]);
      }
      RetrieveVideos();
      return;
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.loadsCompleted, props.videos, props.amount]);

  useEffect(() => {
    if (props.replaceRecommended) {
      ChangeRecommendedVideos(videos);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [videos]);

  return (
    <div
      className={"videoList " + (props.singleRow ? "singleRow" : "multiRow")}
    >
      {props.singleRow ? (
        <VideoListSingleRow
          {...props}
          videos={videos?.filter((v) => v?.id != null)}
          numberOfPlaceholders={props.amount}
        />
      ) : (
        <VideoListMultiRow
          {...props}
          videos={videos?.filter((v) => v?.id != null)}
          numberOfPlaceholders={props.amount}
        />
      )}
    </div>
  );
}

export default VideoList;
