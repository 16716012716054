import React, { Fragment, useEffect, useState } from "react";
import useIsMobile from "../../../Hooks/useIsMobile";
import * as SiteConstants from "../../Constants/SiteConstants";
import { UseNavigationContext } from "../../Contexts/NavigationContext";
import { UseVideoContext } from "../../Contexts/VideoContext";
import VideoList from "../../Lists/VideoList";

function CategoryPage(props) {
  const { isMobile, currentWindowWidth } = useIsMobile(768);

  const CalculateVideoAmount = () => {
    if (!isMobile) {
      return 4;
    } else if (currentWindowWidth < 554) {
      return 3;
    } else if (currentWindowWidth < 732) {
      return 5;
    } else {
      return 3;
    }
  };

  const videoAmount = CalculateVideoAmount();

  const slug = window.location.pathname.split("/")[2];
  const [category, SetCategory] = useState(null);

  const { HandleLinkClick } = UseNavigationContext();
  const { GetCategories } = UseVideoContext();

  function ProcessTimeline() {
    if (!category.timeline) {
      return [];
    }
    let timeline = category.timeline.split(",");
    return timeline;
  }

  useEffect(() => {
    async function GetCategory() {
      if (category?.slug === slug) {
        return;
      }
      let newCategory = await GetCategories({
        slug: slug,
      });
      if (newCategory?.data instanceof Array) {
        SetCategory(newCategory.data[0]);
      }
    }
    GetCategory();
  }, [slug, GetCategories, category]);

  if (
    category != null &&
    slug !== "" &&
    category.slug === slug &&
    (category.description == null ||
      category.description === "" ||
      category.heroImage == null ||
      category.heroImage === "")
  ) {
    HandleLinkClick(null, `/videos/${category.slug}/all-time/date`);
  }

  return category ? (
    <div className="categoryPage">
      <div className="heroBanner">
        {category.heroImage?.file ? (
          <img
            src={SiteConstants.IMAGES_BASE_URL + category.heroImage.file}
            alt="Category Banner"
            className="heroImage"
          />
        ) : null}
        <div className="categoryTitle">{category.name}</div>
      </div>
      <div className="categoryPageInner">
        <div className="subtitle">{category.subtitle}</div>
        <div className="descriptionContainer">
          <div className="description moreReadable">
            {category.description
              .split("\n")
              .map(
                (paragraph, index) =>
                  paragraph.trim().length > 0 && <p key={index}>{paragraph}</p>
              )}
          </div>
        </div>
        <div className="categorylists">
          <VideoList
            title="LATEST IN CATEGORY"
            singleRow={!isMobile}
            sortBy="date"
            listId="categoryLatest"
            categories={[category.id]}
            loadsCompleted={1}
            amount={videoAmount}
            hideArrows={true}
            adAtStart={true}
            moreLink={"/videos/" + category.slug + "/all/date"}
            replaceRecommended={true}
            forceBoxAds={true}
            maxVideos={3}
          />
          <VideoList
            title="HIGHEST VIEWED IN CATEGORY"
            singleRow={!isMobile}
            sortBy="views"
            listId="categoryTrending"
            categories={[category.id]}
            loadsCompleted={1}
            amount={videoAmount}
            hideArrows={true}
            adAtEnd={true}
            moreLink={"/videos/" + category.slug + "/all/views"}
            forceBoxAds={true}
            maxVideos={3}
          />
        </div>
        {category.timeline ? (
          <>
            <div className="timelineTitle">TIMELINE</div>
            <div className="timeline">
              {ProcessTimeline().map((event, index) => (
                <Fragment key={index}>
                  <div className="timelineEvent">{event}</div>
                  {index < ProcessTimeline().length - 1 && (
                    <div className="timelineDivider">{">"}</div>
                  )}
                </Fragment>
              ))}
            </div>
          </>
        ) : null}
      </div>
    </div>
  ) : (
    <div className="categoryPage">Loading...</div>
  );
}

export default CategoryPage;
