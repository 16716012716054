import React, { useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import { UseNavigationContext } from "../../Contexts/NavigationContext";

function BaseRedirector(props) {
  const { category } = useParams();
  const { HandleLinkClick } = UseNavigationContext();
  const hasRedirected = useRef(false);

  useEffect(() => {
    function ProcessRedirection() {
      const redirectUrl =
        props.redirectTo.replace("{category}", category) +
        window.location.search;
      HandleLinkClick(null, redirectUrl);
    }

    if (!hasRedirected.current) {
      hasRedirected.current = true;
      ProcessRedirection();
    }
  }, [HandleLinkClick, category, props.redirectTo]);

  return (
    <div className="redirectingNotice">
      <div className="openingNotice">Opening Page...</div>
      <div>
        If a new page has not opened please ensure your pop-up blocker is
        disabled for this site.
      </div>
    </div>
  );
}

export default BaseRedirector;
