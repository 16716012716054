import axios from "axios";
import React, { createContext, useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import * as APIConstants from "../Constants/APIConstants";
import { UseUserContext } from "./UserContext";

const CurrentContext = createContext();

function CommentContext({
  children,
  articleId,
  commentsFromVideo,
  username,
  amountPerPage = 20,
  totalAmount,
  allOneLevel = false,
  hideBottomPageSelector = false,
  sectionTitle,
  sortType,
}) {
  const [comments, SetComments] = useState(commentsFromVideo ?? []);
  const [sortedComments, SetSortedComments] = useState([]);
  const [userComments, SetUserComments] = useState([]);
  const [basicInfo, SetBasicInfo] = useState({});
  const [commentsLoaded, SetCommentsLoaded] = useState(false);
  const [page, SetPage] = useState(1);
  const [hasChangedPage, SetHasChangedPage] = useState(false);
  const { user, SetReturnToPath } = UseUserContext();
  const Navigate = useNavigate();

  function GenerateBasicInfo() {
    let basicInfoTemp = {
      articleId: articleId,
      canSeeAdminButtons: false,
      username: username,
    };
    if (user?.id != null) {
      basicInfoTemp = {
        ...basicInfoTemp,
        userId: user.id,
        userIsSuperAdmin: user.isSuperuser,
        userIsModerator: user.groups?.some((g) => g.id === 1),
        userIsWriter: user.groups?.some((g) => g.id === 2),
        userIsEditor: user.groups?.some((g) => g.id === 3),
      };
      if (
        basicInfoTemp.userIsSuperAdmin ||
        basicInfoTemp.userIsWriter ||
        basicInfoTemp.userIsModerator
      ) {
        basicInfoTemp.canSeeAdminButtons = true;
      }
    }
    SetBasicInfo(basicInfoTemp);
  }

  function ProcessUserComments(comments) {
    let processedUserComments = comments.slice();
    let commentIdsToRemove = [];
    for (let i = 0; i < processedUserComments.length; i++) {
      if (
        processedUserComments[i].userId != null &&
        processedUserComments[i].userId.toString() ===
          basicInfo.userId.toString()
      ) {
        continue;
      }
      if (
        processedUserComments[i].children != null &&
        processedUserComments[i].children.length > 0
      ) {
        processedUserComments[i] = ProcessUserComments(
          processedUserComments[i].children
        );
        if (
          processedUserComments[i].children != null &&
          processedUserComments[i].children.length > 0
        ) {
          continue;
        }
      }
      commentIdsToRemove.push(processedUserComments[i].id);
    }
    if (commentIdsToRemove.length > 0) {
      processedUserComments = processedUserComments.filter(
        (comment) => !commentIdsToRemove.includes(comment.id)
      );
    }
    return processedUserComments;
  }

  async function GetComments({ id = null }) {
    let response = await axios
      .get(APIConstants.API_URL + "Get?code=" + APIConstants.API_CODE, {
        headers: {
          "Content-Type": "application/json",
          GetType: "Comment",
          SessionKey: user?.session?.sessionKey,
        },
        params: {
          id: id,
          postId: basicInfo.articleId,
          username: basicInfo.username,
          CommentAdmin: user?.isAdmin,
          amount: totalAmount,
        },
      })
      .catch((error) => {
        return error;
      });

    if (response.data != null && response.data instanceof Array) {
      if (id == null) {
        SetComments(response.data);
        SetCommentsLoaded(true);
      } else {
        let commentsCopy = comments.slice();
        let commentIndex = commentsCopy.findIndex((c) => c.id === id);
        if (response.data.length > 0) {
          commentsCopy[commentIndex] = response.data[0];
        }
        SetComments(commentsCopy);
      }
    }

    return response;
  }

  function LoginCheck(action) {
    if (user?.id == null) {
      if (action == null) {
        return false;
      }
      if (
        window.confirm(
          "You must be logged in to " + action + ". \n Click OK to sign up."
        )
      ) {
        let redirectHost = window.location.host;
        if (redirectHost.includes("blog.")) {
          redirectHost = redirectHost.replace("blog.", "");
        }
        SetReturnToPath(window.location.pathname + window.location.search);
        Navigate("/register");
      }
      return false;
    }
    return true;
  }

  function GenerateCommentCountText() {
    if (basicInfo.articleId == null && basicInfo.username != null) {
      return sectionTitle ?? "Comments";
    }

    if (!commentsLoaded) {
      return "Loading Comments...";
    }
    if (!(comments instanceof Array)) {
      return "Error Loading Comments";
    }
    if (comments.length === 0) {
      return "Be The First To Comment!";
    }
    let commentCount = comments.length;
    let commentCountDisplay = commentCount + " Comment";
    if (commentCount !== 1) {
      commentCountDisplay += "s";
    }
    let statCircleElement = document.getElementById("commentCountStatCircle");
    if (statCircleElement != null) {
      statCircleElement.innerHTML = commentCount;
    }
    return commentCountDisplay;
  }

  function FullRefresh() {
    GetComments({});
  }

  function RefreshComment(commentId) {
    GetComments(commentId);
  }

  function SortComments() {
    let sortedCommentsTemp = comments?.slice() ?? [];
    sortedCommentsTemp.sort(CommentSort);
    SetSortedComments(sortedCommentsTemp);
  }

  function CommentSort(a, b) {
    if (a == null || b == null) {
      return 0;
    }
    if (sortType == null) {
      if (CheckAward(a, b, "pinned") !== 0) {
        return CheckAward(a, b, "pinned");
      }
      if (CheckAward(a, b, "topComment") !== 0) {
        return CheckAward(a, b, "topComment");
      }
      if (CheckAward(a, b, "joker") !== 0) {
        return CheckAward(a, b, "joker");
      }
      if (CheckAward(a, b, "masterDebater") !== 0) {
        return CheckAward(a, b, "masterDebater");
      }
    }
    if (sortType == null || sortType === "score") {
      if (a.score > b.score) {
        return -1;
      }
      if (a.score < b.score) {
        return 1;
      }
    }
    if (sortType == null) {
      if (new Date(a.createdDate) < new Date(b.createdDate)) {
        return -1;
      }
      if (new Date(a.createdDate) > new Date(b.createdDate)) {
        return 1;
      }
    }
    if (sortType === "date") {
      if (new Date(a.createdDate) < new Date(b.createdDate)) {
        return 1;
      }
      if (new Date(a.createdDate) > new Date(b.createdDate)) {
        return -1;
      }
    }
    return 0;
  }

  function CheckAward(a, b, award) {
    if (a[award] === b[award]) {
      return 0;
    }
    if (!a[award]) {
      return 1;
    }
    if (!b[award]) {
      return -1;
    }
  }

  async function CreateComment({
    postId = null,
    content = null,
    parentId = null,
  }) {
    if (!LoginCheck("comment")) {
      return;
    }
    if (articleId == null || content == null) {
      return;
    }
    let formData = new FormData();
    formData.append("postId", postId);
    formData.append("userId", user.id);
    formData.append("_content", content);
    if (parentId != null) {
      formData.append("parentId", parentId);
    }
    let response = await axios.post(
      APIConstants.API_URL + "Add?code=" + APIConstants.API_CODE,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
          AddType: "Comment",
          SessionKey: user.session.sessionKey,
        },
      }
    );
    return response;
  }

  async function EditComment({ id = null, fields = null }) {
    if (!LoginCheck("modify comments")) {
      return;
    }
    if (id == null || fields == null || fields.length === 0) {
      return;
    }
    let formData = new FormData();
    formData.append("id", id);
    fields.forEach((field) => {
      formData.append(field.name, field.value);
    });
    let response = await axios
      .post(
        APIConstants.API_URL + "Update?code=" + APIConstants.API_CODE,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            UpdateType: "Comment",
            SessionKey: user?.session?.sessionKey,
          },
        }
      )
      .catch((error) => {
        return error;
      });
    return response;
  }

  async function ProcessBanRequest({ userId = null, banType = null }) {
    if (!LoginCheck("ban users")) {
      return;
    }
    if (userId == null || banType == null) {
      return;
    }
    let formData = new FormData();
    formData.append("UserId", userId);
    formData.append("BanType", banType);
    let response = await axios
      .post(
        APIConstants.API_URL + "UpdateCommentBan?code=" + APIConstants.API_CODE,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            SessionKey: user?.session?.sessionKey,
          },
        }
      )
      .catch((error) => {
        return error;
      });
    return response;
  }

  const parentComments = sortedComments.filter(
    (c) => c.parentId == null || allOneLevel
  );

  const totalPages =
    parentComments.length > 0
      ? Math.ceil(parentComments.length / amountPerPage)
      : 1;

  function HandlePageChange(pageNumber) {
    SetHasChangedPage(true);
    switch (pageNumber) {
      case "first":
        SetPage(1);
        break;
      case "previous":
        if (page > 1) SetPage(page - 1);
        break;
      case "next":
        if (page < totalPages) SetPage(page + 1);
        break;
      case "last":
        SetPage(totalPages);
        break;
      default:
        SetPage(pageNumber);
        break;
    }
  }

  useEffect(() => {
    if (
      sortedComments.length > 0 &&
      basicInfo.userId != null &&
      basicInfo.userId !== 0 &&
      basicInfo.userId !== "" &&
      basicInfo.articleId != null
    ) {
      SetUserComments(ProcessUserComments(comments));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortedComments]);

  useEffect(() => {
    if (basicInfo.articleId != null || basicInfo.username != null) {
      FullRefresh();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [basicInfo]);

  useEffect(() => {
    GenerateBasicInfo();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [articleId, user, username]);

  useEffect(() => {
    SortComments();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [comments]);

  useEffect(() => {
    if (sortedComments.length === 0 || !hasChangedPage) {
      return;
    }
    let commentCountElement = document.getElementById("commentsCountDiv");
    if (commentCountElement != null) {
      commentCountElement.scrollIntoView({ behavior: "smooth" });
    }
  }, [page, hasChangedPage, sortedComments]);

  return (
    <CurrentContext.Provider
      value={{
        comments,
        sortedComments,
        parentComments,
        userComments,
        basicInfo,
        commentsLoaded,
        amountPerPage,
        hideBottomPageSelector,
        page,
        totalPages,
        allOneLevel,
        GetComments,
        CreateComment,
        EditComment,
        LoginCheck,
        GenerateCommentCountText,
        FullRefresh,
        RefreshComment,
        ProcessBanRequest,
        HandlePageChange,
      }}
    >
      {children}
    </CurrentContext.Provider>
  );
}

export function UseCommentContext() {
  const context = useContext(CurrentContext);
  if (!context) {
    throw new Error("useCommentContext must be used within a Context");
  }
  return context;
}

export default CommentContext;
